import '@/styles/globals.css'
import "swiper/css";
import 'flowbite';
import Script from 'next/script'
import Head from 'next/head';
import Layout from '../components/static/_layout'
import { Inter } from 'next/font/google'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import React from "react";
export const MyContext = React.createContext();

const inter = Inter({ subsets: ['latin'] })
const App = ({ Component, pageProps }) => {

    const [googleLoschenValue, setGoogleLoschenValue] = useState(1);
    const [jamedaLoschenValue, setJamedaLoschenValue] = useState(1);
    const [isSelected, setIsSelected] = useState(false);
    const [priceLoschen, setPriceLoschen] = useState(200);
    const [selectedValue, setSelectedValue] = useState(0);
    const [selectedPlatform, setSelectedPlatform] = useState("");
    const [globalValue, setGlobalValue] = useState(1);


    const router = useRouter()

    useEffect(() => {
        import('react-facebook-pixel')
            .then((x) => x.default)
            .then((ReactPixel) => {
                ReactPixel.init('6670150063005675') // facebookPixelId
                ReactPixel.pageView()

                router.events.on('routeChangeComplete', () => {
                    ReactPixel.pageView()
                })
            })
    }, [router.events])


    return (
        <MyContext.Provider value={{
            googleLoschenValue, setGoogleLoschenValue,
            jamedaLoschenValue, setJamedaLoschenValue,
            isSelected, setIsSelected,
            priceLoschen, setPriceLoschen,
            selectedValue, setSelectedValue,
            globalValue,
            setGlobalValue,
            selectedPlatform, setSelectedPlatform
        }}>
            <Layout className={inter.className}>
                <style jsx global>{`
            html {
            font-family: ${inter.style.fontFamily};
            }
        `}</style>

                <Head>
                    {/* Facebook */}
                    {/* SEO JSON Scheme */}
                    <script type={"application/ld+json"} dangerouslySetInnerHTML={{
                        __html: `
                            {
                    "@context": "https://schema.org/",
                    "@type": "Product",
                    "name": "Google Bewertungen Kaufen",
                    "image": "https://funfsterne.de/_next/image?url=%2Fimages%2Fsubpage%2Fgoogle%2Flogo.png&w=128&q=75",
                    "description": "Positive Google Bewertungen sind sehr wichtig, insbesondere für Unternehmen, die online präsent sind. Google berücksichtigt Bewertungen und Rezensionen als Ranking-Faktoren für lokale Suchergebnisse. Unternehmen mit vielen positiven Bewertungen haben daher eine bessere Chance, in den Suchergebnissen höher platziert zu werden. Dies kann zu einer höheren Sichtbarkeit führen und potenziell mehr Kunden anziehen.",
                    "brand": {
                        "@type": "Brand",
                        "name": "Google"
                    },
                    "review": {
                        "@type": "Review",
                        "reviewRating": {
                            "@type": "Rating",
                            "ratingValue": "4.9",
                            "bestRating": "5"
                        },
                        "author": {
                            "@type": "Organization",
                            "name": "Funfsterne.de"
                        }
                    },
                    "aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue": "4.9",
                        "reviewCount": "298"
                    },
                    "offers": {
                        "@type": "Offer",
                        "url": "https://funfsterne.de/platform/google-bewertungen-kaufen",
                        "priceCurrency": "EUR",
                        "price": "19.99",
                        "priceValidUntil": "2023-07-20"
                    }
                },
            ` }} />
                    <script type={"application/ld+json"} dangerouslySetInnerHTML={{
                        __html: `
                    {
                    "@context": "https://schema.org/",
                    "@type": "Product",
                    "name": "Google Local Guide Bewertungen Kaufen",
                    "image": "https://funfsterne.de/_next/image?url=%2Fimages%2Fsubpage%2Flocal-guide%2Flogo.png&w=128&q=75",
                    "description": "Positive Google Local Guide Bewertungen sind sehr wichtig, insbesondere für Unternehmen, die online präsent sind. Google Local Guide berücksichtigt Bewertungen und Rezensionen als Ranking-Faktoren für lokale Suchergebnisse. Unternehmen mit vielen positiven Bewertungen haben daher eine bessere Chance, in den Suchergebnissen höher platziert zu werden. Dies kann zu einer höheren Sichtbarkeit führen und potenziell mehr Kunden anziehen.",
                    "brand": {
                        "@type": "Brand",
                        "name": "Google"
                    },
                    "review": {
                        "@type": "Review",
                        "reviewRating": {
                            "@type": "Rating",
                            "ratingValue": "4.9",
                            "bestRating": "5"
                        },
                        "author": {
                            "@type": "Organization",
                            "name": "Funfsterne.de"
                        }
                    },
                    "aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue": "4.9",
                        "reviewCount": "139"
                    },
                    "offers": {
                        "@type": "Offer",
                        "url": "https://funfsterne.de/platform/google-bewertungen-kaufen",
                        "priceCurrency": "EUR",
                        "price": "29.99",
                        "priceValidUntil": "2023-07-20"
                    }
                },
            ` }} />
                    <script type={"application/ld+json"} dangerouslySetInnerHTML={{
                        __html: `
    {
                    "@context": "https://schema.org/",
                    "@type": "Product",
                    "name": "Trustpilot Bewertungen Kaufen",
                    "image": "https://funfsterne.de/_next/image?url=%2Fimages%2Fsubpage%2Ftrustpilot%2Flogo.png&w=96&q=75",
                    "description": "Positive Trustpilot Bewertungen sind sehr wichtig, insbesondere für Unternehmen, die online präsent sind. Trustpilot berücksichtigt Bewertungen und Rezensionen als Ranking-Faktoren für lokale Suchergebnisse. Unternehmen mit vielen positiven Bewertungen haben daher eine bessere Chance, in den Suchergebnissen höher platziert zu werden. Dies kann zu einer höheren Sichtbarkeit führen und potenziell mehr Kunden anziehen.",
                    "brand": {
                        "@type": "Brand",
                        "name": "Trustpilot"
                    },
                    "review": {
                        "@type": "Review",
                        "reviewRating": {
                            "@type": "Rating",
                            "ratingValue": "4.9",
                            "bestRating": "5"
                        },
                        "author": {
                            "@type": "Organization",
                            "name": "Funfsterne.de"
                        }
                    },
                    "aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue": "4.9",
                        "reviewCount": "139"
                    },
                    "offers": {
                        "@type": "Offer",
                        "url": "https://funfsterne.de/platform/trustpilot-bewertungen-kaufen",
                        "priceCurrency": "EUR",
                        "price": "25.99",
                        "priceValidUntil": "2023-07-20"
                    }
                },
            ` }} />
                    <script type={"application/ld+json"} dangerouslySetInnerHTML={{
                        __html: `
    {
                    "@context": "https://schema.org/",
                    "@type": "Product",
                    "name": "Arbeitgeber Bewertungen Kaufen",
                    "image": "https://funfsterne.de/_next/image?url=%2Fimages%2Fsubpage%2Farbeitgeber%2Flogo.png&w=128&q=75",
                    "description": "Positive Arbeitgeber Bewertungen sind sehr wichtig, insbesondere für Unternehmen, die online präsent sind. Arbeitgeber berücksichtigt Bewertungen und Rezensionen als Ranking-Faktoren für lokale Suchergebnisse. Unternehmen mit vielen positiven Bewertungen haben daher eine bessere Chance, in den Suchergebnissen höher platziert zu werden. Dies kann zu einer höheren Sichtbarkeit führen und potenziell mehr Kunden anziehen.",
                    "brand": {
                        "@type": "Brand",
                        "name": "Arbeitgeber"
                    },
                    "review": {
                        "@type": "Review",
                        "reviewRating": {
                            "@type": "Rating",
                            "ratingValue": "4.9",
                            "bestRating": "5"
                        },
                        "author": {
                            "@type": "Organization",
                            "name": "Funfsterne.de"
                        }
                    },
                    "aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue": "4.9",
                        "reviewCount": "139"
                    },
                    "offers": {
                        "@type": "Offer",
                        "url": "https://funfsterne.de/platform/arbeitgeber-bewertungen-kaufen",
                        "priceCurrency": "EUR",
                        "price": "23.99",
                        "priceValidUntil": "2023-07-20"
                    }
                },
            ` }} />
                    <script type={"application/ld+json"} dangerouslySetInnerHTML={{
                        __html: `
    {
                    "@context": "https://schema.org/",
                    "@type": "Product",
                    "name": "Wunschplattform Bewertungen Kaufen",
                    "image": "https://funfsterne.de/_next/image?url=%2Fimages%2Fsubpage%2Fwunschplattform%2Flogo.png&w=128&q=75",
                    "description": "Positive Wunschplattform Bewertungen sind sehr wichtig, insbesondere für Unternehmen, die online präsent sind. Wunschplattform berücksichtigt Bewertungen und Rezensionen als Ranking-Faktoren für lokale Suchergebnisse. Unternehmen mit vielen positiven Bewertungen haben daher eine bessere Chance, in den Suchergebnissen höher platziert zu werden. Dies kann zu einer höheren Sichtbarkeit führen und potenziell mehr Kunden anziehen.",
                    "brand": {
                        "@type": "Brand",
                        "name": "Wunschplattform"
                    },
                    "review": {
                        "@type": "Review",
                        "reviewRating": {
                            "@type": "Rating",
                            "ratingValue": "4.9",
                            "bestRating": "5"
                        },
                        "author": {
                            "@type": "Organization",
                            "name": "Funfsterne.de"
                        }
                    },
                    "aggregateRating": {
                        "@type": "AggregateRating",
                        "ratingValue": "4.9",
                        "reviewCount": "139"
                    },
                    "offers": {
                        "@type": "Offer",
                        "url": "https://funfsterne.de/platform/arbeitgeber-bewertungen-kaufen",
                        "priceCurrency": "EUR",
                        "price": "25.99",
                        "priceValidUntil": "2023-07-20"
                    }
                }
            ` }} />
                    {/*END SEO JSON Scheme*/}

                    <link rel="icon" type="image/x-icon" href="/images/global/favicon-funfsterne.ico" />
                    <meta charSet="UTF-8" />
                    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                    <meta name="robots" content="index, follow" />
                    <link rel="canonical" href="https://funfsterne.de/" />
                    <meta name="description" content="✅ Repräsentativ ✅Bewertungen von echten Menschen ⭐️ Bewertungen kaufen: Seriös ➜ Kostenlose Erstberatung ☎ +49 30 200 09 515" />
                    <meta property='og:description' content="✅ Repräsentativ ✅Bewertungen von echten Menschen ⭐️ Bewertungen kaufen: Seriös ➜ Kostenlose Erstberatung ☎ +49 30 200 09 515" key="desc" />
                    <meta property="og:locale" content="de_DE" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Funfsterne ⭐️ Positive Google Bewertungen Kaufen" />
                    <meta property="og:image" content="" />
                    <meta property="og:url" content="https://funfsterne.de/" />
                    <meta property="og:site_name" content="Funfsterne - 5 Sterne Google Bewertungen kaufen" />
                    <meta property="article:modified_time" content="2022-12-01T20:00:28+00:00" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:label1" content="Geschätzte Lesezeit" />
                    <meta name="twitter:data1" content="4 Minuten" />

                </Head>
                <Component {...pageProps} />
            </Layout>
        </MyContext.Provider>
    )
}




export default App



