import { useRouter } from 'next/router';
import de from '../../locales/de/language';
import en from '../../locales/en/language';
import es from '../../locales/es/language';
import it from '../../locales/it/language';
import fr from '../../locales/fr/language';

export function useTranslation() {
    const router = useRouter();
    const { locale } = router;

    let translations;

    switch (locale) {
        case 'de':
            translations = de;
            break;
        case 'en':
            translations = en;
            break;
        case 'es':
            translations = es;
            break;
        case 'it':
            translations = it;
            break;
        case 'fr':
            translations = fr;
            break;
        default:
            translations = de;
    }

    return translations;
}