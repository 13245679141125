export default {

  // Global

  "lang": "en",
  "home": "Home",
  "services": "Services",
  "process": "Process",
  "price": "Prices",
  "faq": "FAQ",
  "contact": "Contact Us",
  "button": "Book Now",
  "button2": "Send Inquiry",
  "loading": "Loading",
  "reviews": "Reviews",
  "review": "Review",
  "clear": "Clear",
  "clear2": "Clearance",
  "agb": "Terms and Conditions",
  "privacy_policy": "Privacy Policy",
  "legal": "Legal",
  "impressum": "Imprint",
  "name_surname": "Name",
  "buy_reviews": "Buy Reviews",
  "visit_us": "Visit us to see the details",
  "buy_clear": "Buy Clearance",
  "copyright": "Copyright © 2011 All rights reserved",
  "address": "Kurfürstendamm 45, 10707 Berlin",
  "slogan": "We Save Your Reputation!",
  "email_required": "Email is required",
  "email_invalid": "Invalid email format",
  "phone_required": "Phone is required",
  "package_id_required": "Please select a package",
  "phone_invalid": "Invalid phone number format",
  "phone": "Phone Number",
  "order_complete": "Your message has been received",
  "order_not_complete": "We couldn't receive your message",
  "message": "Message",
  "email": "Email",
  "kdv": "incl. VAT",
  "positive": "Positive",
  "negative": "Negative",
  "garantie": "Satisfaction guarantee",
  "loschungData": "Pro deleted review",
  "select_package": "Which package are you interested in?",
  "basic": "Beginner",
  "business": "Business",
  "pro": "Pro",
  "loschung1": "Up to",
  "loschung2": "From",


  "url_google": "buy-google-reviews",
  "url_google_local_guide": "buy-google-local-guide-reviews",
  "url_trustpilot": "buy-trustpilot-reviews",
  "url_arbeitgeber": "buy-employer-reviews",
  "url_google_clear": "delete-google-reviews",
  "url_jameda_clear": "delete-jameda-reviews",
  "url_wunschplattform": "buy-desired-platform-reviews",

  "employer": "Employer",
  "wunschplattform": "Desired Platform",



  // Home Hero
  "home_hero_title": "<h1>Google Reviews <br/> Professional, Discreet <br/> Authentic.</h1>",
  "home_hero_description": "The springboard for your reputation and success! Trust the power of opinions!",
  "home_hero_list_item1": "Customized Content",
  "home_hero_list_item2": "Personal Turnaround",
  "home_hero_list_item3": "Satisfaction guarantee",
  "home_hero_button": "Check Prices",

  "rating": "With 35,000 serviced companies and a satisfaction rate of 98%, we have proven that customer satisfaction is our top priority.",

  // Home Step 
  "home_step1_title": "Real Users, Not Robots.",
  "home_step1_description": "Getting reviews from real users and staying away from bot-generated reviews is crucial. With us, you will only receive reviews from verified local users.",
  "home_step1_list_item1": "Over 50,000 reviewers throughout Germany, Austria, and Switzerland",
  "home_step1_list_item2": "German names, verified accounts",
  "home_step1_list_item3": "Natural profile photos and histories",
  "home_step2_title": "Google Reviews",
  "home_step2_description": "<p>The importance of Google reviews lies in their significant influence on customer trust and decision-making. They provide customers with the opportunity to see other people's experiences and draw conclusions about customer satisfaction and the reputation of a business. Positive reviews can increase trust and willingness to purchase, while negative reviews can deter potential customers. Thus, Google reviews directly impact the success and reputation of a business in the digital age.</p>",
  "home_step3_title": "Why Should You Buy Reviews?",
  "home_step3_description": "<p>According to a survey by BrightLocal, 86% of consumers trust online reviews as much as personal recommendations. A study by Harvard Business School found that an increase of one star in rating can lead to an average revenue increase of 5-9%. According to a study by Moz, the number and quality of reviews have a direct impact on a company's ranking in local search results. A survey by ReviewTrackers shows that 94% of consumers read negative reviews before making a purchase decision. This highlights the importance of taking negative reviews seriously and responding to them.</p>",

  // Price
  "price_description": "No subscription, only one-time booking - flexible and uncomplicated for your desired service.",
  "price_total": "Total Amount",

  // Services
  "services_title": "What is the process of publishing a positive review?",
  "services_item1_title": "Choose the package that suits you",
  "services_item1_description": "Select the platform-specific package that suits your needs",
  "services_item2_title": "Specify the details of your campaign",
  "services_item2_description": "Our customer representatives will contact you to discuss the campaign",
  "services_item3_title": "Text Contents",
  "services_item3_description": "Provide us with your own texts or let us create them for you, tailored to your business",
  "services_item4_title": "Photos in the Reviews",
  "services_item4_description": "Upon request, we can provide reviews with photos. You need to provide us with the photos.",
  "services_item5_title": "Start of Distribution",
  "services_item5_description": "Once all preparations are made, we will start the distribution. You don't have to worry about anything.",
  "services_item6_title": "Reporting",
  "services_item6_description": "After the completion of the distribution, you will receive a detailed report on the reviews that have been posted.",


  "services_title_jameda": "Wie ist der Ablauf der Löschung einer negativen Bewertung?",
  "services_item1_title_jameda": "Negative Einträge",
  "services_item1_description_jameda": "Überprüfung der Anzahl der negativen Bewertungen Wählen Sie die gewünschte Anzahl aus",
  "services_item2_title_jameda": "Anfrageformular ausfüllen",
  "services_item2_description_jameda": "Schildern Sie präzise, welche Bewertung Sie gelöscht haben möchten. Füllen Sie die Anfrage aus und senden Sie es ab.",
  "services_item3_title_jameda": "Kostenlose Beratung",
  "services_item3_description_jameda": "Wir informieren Sie über den Ablauf des Löschungsprozesses. Erstellung eines individuellen Angebotes. ",
  "services_item4_title_jameda": "Angebotseingang",
  "services_item4_description_jameda": "Sie erhalten von uns ein Angebot. Übersenden Sie uns eine Auftragsbestätigung. Auf Wunsch starten wir die Kampagne.",
  "services_item5_title_jameda": "Erfolgreiche Löschung",
  "services_item5_description_jameda": "Zahlung erst am Ende der Leistung. Sie zahlen nur für gelöschte Bewertungen.",
  "services_item6_title_jameda": "Benachrichtigung",
  "services_item6_description_jameda": "Nach Abschluss informieren wir Sie zu den gelöschten Bewertungen.",

  // Platform

  "platform_title": "We're Not Just Google Experts",

  // FAQ
  "faq_title": "Frequently Asked Questions",
  "question_1": "Can you buy Google reviews?",
  "answer_1": "Funfsterne.de is the market leader in reputation management and has been offering professional and high-quality reviews since 2011.",
  "question_2": "How much does a Google review cost?",
  "answer_2": "Positive Google reviews are the easiest and most affordable way to attract new customers and significantly increase revenue. For just 650.00, you will receive 25 high-quality Google reviews.",
  "question_3": "Can you buy bad Google reviews?",
  "answer_3": "At Funfsterne, you can only buy positive reviews and the removal of negative reviews. We do not offer negative reviews.",
  "question_4": "How much does it cost to delete a Google review?",
  "answer_4": "The deletion of a negative review starts at €175.00.",
  "question_5": "Why does Google delete positive reviews?",
  "answer_5": "Google can delete positive reviews if they violate their policies. Therefore, it is essential that the reviews are compliant with Google guidelines. Additionally, Google algorithms can detect program-generated reviews. Hence, it is crucial to obtain reviews from professional providers and their verified users.",
  "question_6": "How meaningful are Google reviews?",
  "answer_6": "Google reviews can be a valuable source of information to get an idea about a company, product, or service. Here are some factors that can influence the significance of Google reviews:\n" +
    "<br/> <br/>" +
    "1 - Number of reviews: A higher number of reviews generally provide a better overall picture and reduce the risk of individual opinions that may not be representative.\n" +
    "<br/> <br/>" +
    "2 - Quality of reviews: It is advisable not to focus solely on the star rating. Detailed and comprehensive reviews provide deeper insights and can build more trust.\n" +
    "<br/> <br/>" +
    "3 - Diversity of reviews: It is helpful to consider reviews from different people with different perspectives to get a balanced picture.\n" +
    "<br/> <br/>" +
    "4 - Timeliness of reviews: It is important to note that a company's performance can change over time. Therefore, more recent reviews should carry more weight than older reviews.",
  "question_7": "Can you review a business on Google yourself?",
  "answer_7": "Certainly, as a Google user, you can leave a review; however, the reviewed profile must not be your own.",

  "question_1_jameda": "Welche Kosten entstehen bei der Löschung einer Jameda-Bewertung?",
  "answer_1_jameda": "Die Kosten für die Löschung einer Jameda-Bewertung entstehen nur nach erfolgreicher Entfernung der Bewertung. Somit tragen Sie kein Kostenrisiko.",
  "question_2_jameda": "Verkaufen Sie positive Jameda Bewertungen?",
  "answer_2_jameda": "Nein, wir verkaufen keine positiven Jameda-Bewertungen.Unser Service ist ausschließlich auf die Löschung negativer Bewertungen spezialisiert.",
  "question_3_jameda": "Was kostet es eine Jameda Bewertung zu löschen?",
  "answer_3_jameda": "Die Löschung einer negativen Bewertung auf Jameda liegt bei <strong>350,00€</strong> zzgl. MwSt.",
  "question_4_jameda": "Was passiert, wenn die negative Bewertung erneut auftaucht?",
  "answer_4_jameda": "Erscheint eine gelöschte Bewertung erneut und stammt vom selben Rezensenten, wird die erneute Löschung kostenlos durchgeführt.",
  "question_5_jameda": "Wie lange dauert der Löschungsprozess einer Jameda-Bewertung?",
  "answer_5_jameda": "Der Löschungsprozess dauert in der Regel 6-8 Wochen.",
  "question_6_jameda": "Werden alle negativen Bewertungen gelöscht oder nur bestimmte?",
  "answer_6_jameda": "Nicht alle negativen Bewertungen können gelöscht werden. Jede Bewertung wird einzeln und nach den jeweiligen Richtlinien geprüft. Dies kann zu unterschiedlichen Zeitpunkten erfolgen.",

  // Process
  "process_hero_title": "Buy Review <br /> Take Action <br /> Very Easy.",
  "process_hero_description": "We have an excellent team of experts who create high-quality services and support our customers on the go. We have been providing the review service since the beginning of online commerce, when it became the primary place of marketing.",

  "process_step_1_title": "Google Reviews",
  "process_step_1_description": "Google's search engine enhances businesses' potential by allowing them to be displayed at the top of the list. <br/> <br/> Those who use the navigation system consider the businesses that appear in the top ranks.",

  "process_step_2_title": "Why Should I Buy Google Maps Reviews?",
  "process_step_2_description": "The number of reviews a business receives is always important to visitors. Businesses with few comments and low star ratings are less preferred by visitors as they do not inspire trust. That's why the Google Maps review service is one of your must-haves.",

  "process_step_3_title": "Google Reviews",
  "process_step_3_description": "Google's search engine enhances businesses' potential by allowing them to be displayed at the top of the list. <br/> <br/> Those who use the navigation system consider the businesses that appear in the top ranks.",

  "process_step_4_title": "Why Should I Buy Google Maps Reviews?",
  "process_step_4_description": "The number of reviews a business receives is always important to visitors. Businesses with few comments and low star ratings are less preferred by visitors as they do not inspire trust. That's why the Google Maps review service is one of your must-haves.",

  "process_services_title": "How Does the Process of Publishing a Positive Review Work?",
  "process_services_description_1": "<p>The quickest and most effective way to stand out from competitors is to gain more visibility through positive customer reviews.</p><br/><p>According to recent studies, 86% of all customers decide on the company with the most reviews and better average ratings in search results. <br/> The quickest and most effective way to stand out from competitors is to gain more visibility through positive customer reviews.</p><br/><p>According to recent studies, 86% of all customers decide on the company with the most reviews and better average ratings in search results.</p>",
  "process_services_description_2": "<p>The quickest and most effective way to stand out from competitors is to gain more visibility through positive customer reviews.</p><br/><p>According to recent studies, 86% of all customers decide on the company with the most reviews and better average ratings in search results. <br/>The quickest and most effective way to stand out from competitors is to gain more visibility through positive customer reviews.</p><br/><p>According to recent studies, 86% of all customers decide on the company with the most reviews and better average ratings in search results.</p>",

  // Dynamic Page

  "dynamic_page_hero_description": "Positive @@burasidegisecek@@ reviews are very important, especially for businesses that are present online. @@burasidegisecek@@ considers reviews and comments as ranking factors for local search results. Businesses with many positive reviews have a better chance of being ranked higher in search results. This can lead to increased visibility and potentially attract more customers.",

  "dynamic_page_step1_title": "Why are @@burasidegisecek@@ reviews important?",
  "dynamic_page_step1_description": "<p>The importance of @@burasidegisecek@@ reviews lies in their significant influence on customer trust and decision-making.<br/> <br/>They provide customers with the opportunity to see the experiences of other people and draw conclusions about the customer satisfaction and reputation of a business.<br/><br/>Positive reviews can enhance trust and willingness to make a purchase, while negative reviews can deter potential customers. @@burasidegisecek@@ reviews have a direct impact on the success and reputation of a business in the digital age.</p>",
  "dynamic_page_step2_title": "How can you improve your @@burasidegisecek@@ reviews?",
  "dynamic_page_step2_description": "There are several ways to improve your @@burasidegisecek@@ reviews. One option is to encourage your customers to leave reviews and ensure that those reviews are positive. You can also avail professional @@burasidegisecek@@ review management services to enhance your online reputation and strengthen your brand.",

  "dynamic_page_step3_title": "What do the reviews look like?",
  "dynamic_page_step3_description": "We offer a wide selection of over 50,000 reviewers to ensure that you receive reviews that are relevant to your services or products. You have the option to choose reviews from male or female reviewers, young or older individuals. Additionally, you will only receive reviews that align with the textual content of your services or products. This ensures that the reviews are relevant to your business and provide a comprehensive insight into the customer experience.",

  // Dynamic Deletion
  "dynamic_page_loeschung_hero_title": "Deleting Google Reviews",
  "dynamic_page_loeschung_hero_description": "While it is desirable to avoid negative Google reviews, in practice, it is not always possible to completely avoid them. Negative reviews can impact the reputation of a business. Potential customers may be deterred and choose competitor companies with better ratings. A poor reputation can have a negative impact on the image and success of a business, as many people read reviews before making a purchase decision.",
  "dynamic_page_loeschung_step1_title": "How can you get rid of negative reviews?",
  "dynamic_page_loeschung_step1_description": "If negative reviews occur frequently and are not addressed properly, it can lead to a loss of customers. With us by your side, you no longer have to worry about defamatory comments or negative reviews related to competition. We help you remove them.",
  "dynamic_page_loeschung_step2_title": "Can negative reviews be removed?",
  "dynamic_page_loeschung_step2_description": "The prospect of having negative reviews removed lies in submitting a formal request to the relevant platform or website on which they were posted. This requires careful consideration of the individual guidelines and protocols established by the platform, as well as adequate justification and documentation to support the request.",


  "dynamic_page_loeschung_jameda_hero_title": "Deleting Jameda Reviews",
  "dynamic_page_loeschung_jameda_hero_description": "While it is desirable to avoid negative Jameda reviews, in practice, it is not always possible to completely avoid them. Negative reviews can impact the reputation of a business. Potential customers may be deterred and choose competitor companies with better ratings. A poor reputation can have a negative impact on the image and success of a business, as many people read reviews before making a purchase decision.",
  "dynamic_page_loeschung_jameda_step1_title": "How can you get rid of negative reviews?",
  "dynamic_page_loeschung_jameda_step1_description": "If negative reviews occur frequently and are not addressed properly, it can lead to a loss of customers. With us by your side, you no longer have to worry about defamatory comments or negative reviews related to competition. We help you remove them.",
  "dynamic_page_loeschung_jameda_step2_title": "Can negative reviews be removed?",
  "dynamic_page_loeschung_jameda_step2_description": "Die Aussicht auf die Entfernung negativer Bewertungen liegt in der Einreichung eines formellen Antrags bei Jameda. Dies erfordert eine genaue Beachtung der individuellen Richtlinien und Verfahren, die von Jameda festgelegt wurden, sowie eine fundierte Begründung und entsprechende Dokumentation zur Unterstützung des Antrags.",

  "data": [
    {
      "Google": [
        {
          "title": "Google Beginner",
          "proBewertung": "25",
          "price": "550",
          "features": [
            "Verified local users",
            "Customized frequency",
            "Customized texts"
          ]
        },
        {
          "title": "Google Business",
          "proBewertung": "50",
          "price": "950",
          "features": [
            "Verified local users",
            "Customized frequency",
            "Customized texts",
            "Personal dashboard"
          ]
        },
        {
          "title": "Google Pro",
          "proBewertung": "100",
          "price": "1.750",
          "features": [
            "10 Local Guide reviews included",
            "Verified local users",
            "Customized frequency",
            "Customized texts",
            "Personal dashboard",
            "Divisible across multiple profiles/locations"
          ]
        }
      ],
      "Google Local Guide": [
        {
          "title": "Google Local Guide Beginner",
          "proBewertung": "25",
          "price": "700",
          "features": [
            "Verified local users",
            "Customized frequency",
            "Customized texts"
          ]
        },
        {
          "title": "Google Local Guide Business",
          "proBewertung": "50",
          "price": "1.200",
          "features": [
            "Verified local users",
            "Customized frequency",
            "Customized texts",
            "Personal dashboard"
          ]
        },
        {
          "title": "Google Local Guide Pro",
          "proBewertung": "100",
          "price": "2.200",
          "features": [
            "Verified local users",
            "Customized frequency",
            "Customized texts",
            "Personal dashboard",
            "Divisible across multiple profiles/locations"
          ]
        }
      ],
      "Trustpilot": [
        {
          "title": "Trustpilot Beginner",
          "proBewertung": "25",
          "price": "875",
          "features": [
            "Verified local users",
            "Customized frequency",
            "Customized texts"
          ]
        },
        {
          "title": "Trustpilot Business",
          "proBewertung": "50",
          "price": "1.700",
          "features": [
            "Verified local users",
            "Customized frequency",
            "Customized texts",
            "Personal dashboard"
          ]
        },
        {
          "title": "Trustpilot Pro",
          "proBewertung": "100",
          "price": "3.300",
          "features": [
            "Verified local users",
            "Customized frequency",
            "Customized texts",
            "Personal dashboard",
            "Divisible across multiple profiles/locations"
          ]
        }
      ],
      "Arbeitgeber": [
        {
          "title": "Employer Beginner",
          "proBewertung": "25",
          "price": "700",
          "features": [
            "Verified local users",
            "Customized frequency",
            "Customized texts"
          ]
        },
        {
          "title": "Employer Business",
          "proBewertung": "50",
          "price": "1.150",
          "features": [
            "Verified local users",
            "Customized frequency",
            "Customized texts",
            "Personal dashboard"
          ]
        },
        {
          "title": "Employer Pro",
          "proBewertung": "100",
          "price": "2.100",
          "features": [
            "Verified local users",
            "Customized frequency",
            "Customized texts",
            "Personal dashboard",
            "Divisible across multiple profiles/locations"
          ]
        }
      ],
      "Google Loschen": [
        {
          "title": "Google Deletion Business",
          "titleleft": "How many negative reviews would you like to remove?",
          "leftdesc1": "We help you remove unwanted and inaccurate reviews quickly and easily, as you do not have to tolerate abusive reviews",
          "leftdesc2": "No cost risk - payment only upon success!",
          "rangeTitle": "Number of reviews to be deleted",
          "rangeText": "Your save",
          "name": "Reviews",
          "garantie": "Satisfaction Guarantee",
          "proBwt": "per deleted review",
          "proBewertung": "5",
          "loschungData3": "From",
          "price": "300",
          "features": [
            "Possible countries: Germany - Austria - Switzerland",
            "Payment at the end of the service.",
            "Payment only for deleted reviews",
            "97% deletion rate",
            "No cost risk."
          ]
        },
      ],
      "Jameda Loschen": [
        {
          "title": "Jameda Deletion Business",
          "titleleft": "How many negative reviews would you like to remove?",
          "leftdesc1": "We help you remove unwanted and inaccurate reviews quickly and easily, as you do not have to tolerate abusive reviews",
          "leftdesc2": "No cost risk - payment only upon success!",
          "rangeTitle": "Number of reviews to be deleted",
          "rangeText": "Your save",
          "name": "Reviews",
          "garantie": "Satisfaction Guarantee",
          "proBwt": "per deleted review",
          "proBewertung": "5",
          "loschungData3": "From",
          "price": "300",
          "features": [
            "Possible countries: Germany - Austria - Switzerland",
            "Payment at the end of the service.",
            "Payment only for deleted reviews",
            "97% deletion rate",
            "No cost risk."
          ]
        }
      ],
      "Wunschplattform": [
        {
          "title": "Preferred Platform Beginner",
          "proBewertung": "25",
          "price": "800",
          "features": [
            "Verified local users",
            "Customized frequency",
            "Customized texts"
          ]
        },
        {
          "title": "Preferred Platform Business",
          "proBewertung": "50",
          "price": "1.250",
          "features": [
            "Verified local users",
            "Customized frequency",
            "Customized texts",
            "Personal dashboard"
          ]
        },
        {
          "title": "Preferred Platform Pro",
          "proBewertung": "100",
          "price": "2.250",
          "features": [
            "Verified local users",
            "Customized frequency",
            "Customized texts",
            "Personal dashboard",
            "Divisible across multiple profiles/locations"
          ]
        }
      ]
    }
  ],


  // Impressum
  "impressum_step1_title": "Responsible for the content according to § 55 Abs. 2 RStV:",
  "impressum_step1_description": "Funfsterne, address as above",
  "impressum_step2_title": "EU Dispute Resolution",
  "impressum_step2_description": "The European Commission provides a platform for online dispute resolution (ODR): https://ec.europa.eu/consumers/odr. You can find our email address above in the imprint.",
  "impressum_step3_title": "Consumer Dispute Resolution / Universal Arbitration Board",
  "impressum_step3_description": "We are not willing or obliged to participate in dispute resolution proceedings before a consumer arbitration board.",
  "impressum_step4_title": "Liability for Content",
  "impressum_step4_description": "The site operator assumes no liability for information transmitted or stored by third parties. The site operator is not obligated to monitor this information. The site operator is liable if he fails to comply with a legitimate request to delete the information, only when the information is unlawfully transmitted or stored by third parties. Obviously unlawful information will be deleted immediately upon becoming aware of it.",
  "impressum_step5_title": "Liability for Links",
  "impressum_step5_description": "The site operator has no influence on external links. Before publishing the links on these pages, they have been checked to the best of their knowledge and belief for compliance with legal requirements. The site operator is not responsible for any changes that may occur on externally linked pages. In particular, continuous monitoring of external links for legal infringements is not reasonable. If the site operator is informed about illegal content accessible via external links, they will remove these links immediately.",
  "impressum_step6_title": "Copyright",
  "impressum_step6_description": "The copyright to all texts and multimedia content accessible on the operator's pages lies with the site operator, unless other copyrights are separately indicated. If the copyrights of third parties are not indicated, please notify us by email. The site operator will promptly remove such content. The processing, distribution, and reproduction of content is not permitted without the express consent of the site operator.",

  // Privacy Policy
  "datenschutz_title1": "General",
  "datenschutz_description1": "Your personal data within the meaning of Art. 4 No. 1 GDPR (e.g., salutation, name, address, email address, payment information) will only be processed by us in accordance with the provisions of German data protection law and taking into account the European General Data Protection Regulation (GDPR). The following provisions inform you about the nature, scope, and purpose of the collection, processing, and use of personal data.",

  "datenschutz_description2": "Processing within the meaning of Art. 4 No. 2 GDPR of personal data is lawful pursuant to Art. 6 GDPR if one of the following conditions is met:",

  "datenschutz_description_list": "<li>The data subject has given consent to the processing of his or her personal data for one or more specific purposes;</li> <li>processing is necessary for the performance of a contract to which the data subject is a party or for the implementation of pre-contractual measures at the request of the data subject;</li><li>processing is necessary for compliance with a legal obligation to which the controller is subject;</li><li>processing is necessary to protect the vital interests of the data subject or of another natural person;</li> <li>processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller;</li> <li>processing is necessary for the purposes of the legitimate interests pursued by the controller or by a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject, which require protection of personal data, in particular where the data subject is a child.</li>",

  "datenschutz_description3": "The processing of special categories of personal data (e.g., health data) within the meaning of Art. 9 (1) GDPR is lawful in particular pursuant to Art. 9 (2) GDPR if one of the following conditions is met:",

  "datenschutz_description4": "<li>The data subject has given explicit consent to the processing of those personal data for one or more specified purposes;</li><li>processing is necessary for the establishment, exercise or defense of legal claims or whenever courts are acting in their judicial capacity.</li>",

  "datenschutz_description5": "There is no automated decision-making or profiling regarding personal data within the meaning of Art. 22 GDPR.",

  "datenschutz_description6": "The operator ensures the security of data in accordance with Art. 32 GDPR, taking into account the principle of proportionality, by means of appropriate technical measures. In the event of a data breach, the competent supervisory authority pursuant to Art. 33 GDPR and the data subject pursuant to Art. 34 GDPR will be notified.",

  "datenschutz_title2": "Scope",
  "datenschutz_description7": "This privacy policy applies only to our websites. If you are redirected to other websites through links on our pages, please inform yourself about the respective handling of your data there.",

  "datenschutz_title3": "Cookies",
  "datenschutz_description8": "This site stores cookies to recognize its visitors. The data obtained through cookies will not be disclosed to third parties. If you still wish to prevent the storage of cookies, you have the option to disable this in your browser settings.",
  "datenschutz_title4": "Google Webfonts",
  "datenschutz_decsription9": "These websites use external fonts, Google Fonts. Google Fonts is a service provided by Google Inc. ('Google'). The integration of these web fonts is done by making a server call to a Google server in the USA, usually to a server operated by Google. This transmits to the server which of our websites you have visited. The IP address of the visitor's browser is also stored by Google.",
  "datenschutz_decsription10": "For more information, please refer to Google's privacy policy, which you can find here:",

  "datenschutz_title5": "Google Analytics",
  "datenschutz_description11": "This website uses Google Analytics, a web analytics service provided by Google Inc. ('Google'). Google Analytics uses 'cookies', which are text files placed on your computer, to help the website analyze how users use the site. The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and stored by Google on servers in the United States. However, if IP anonymization is enabled on this website, your IP address will be truncated by Google within member states of the European Union or other parties to the Agreement on the European Economic Area. Only in exceptional cases will the full IP address be sent to a Google server in the USA and truncated there.",

  "datenschutz_description12": "On behalf of the operator of this website, Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity for website operators, and providing other services relating to website activity and internet usage. The IP address transmitted by your browser as part of Google Analytics will not be merged with any other data held by Google.",

  "datenschutz_description13": "You can prevent the storage of cookies by selecting the appropriate settings in your browser software; however, please note that if you do this, you may not be able to use the full functionality of this website.",
  "datenschutz_description14": "You can also prevent the collection of data generated by the cookie about your use of the website (including your IP address) and its processing by Google by downloading and installing the browser plugin available at the following link:",

  "datenschutz_description15": "Google Analytics uses the anonymization function. A data processing agreement has been concluded with Google Inc.",

  "datenschutz_title6": "Google Tag Manager / Google Ads",
  "datenschutz_description17": "Google Tag Manager is a solution that allows marketers to manage website tags through an interface. The Tag Manager tool itself (which implements the tags) is a cookie-less domain and does not collect any personal data. The tool triggers other tags that may collect data. Google Tag Manager does not access this data. If domain-level or cookie-level deactivation has been performed, it will remain in effect for all tracking tags implemented with Google Tag Manager.",
  "datenschutz_description18": "Different privacy policies apply to this data from the company Google. For more information on Google's privacy policy, please visit:",

  "datenschutz_description19": "A data processing agreement has been concluded with Google Inc.",

  "datenschutz_title7": "Paypal",
  "datenschutz_description20": "When paying via PayPal, credit card via PayPal, direct debit via PayPal, or 'purchase on account' or installment payments via PayPal, we transfer your payment data to PayPal (Europe) S.à r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg ('PayPal') for the purpose of payment processing. PayPal reserves the right to conduct a credit check for payment methods such as credit card via PayPal, direct debit via PayPal, 'purchase on account' via PayPal, or installment payments via PayPal. The result of the credit check regarding the statistical probability of default is used by PayPal for the purpose of deciding whether to provide the respective payment method. The credit check may involve probability values (so-called score values). If score values are included in the result of the credit check, they are based on a scientifically recognized mathematical-statistical procedure. The calculation of score values includes, among other things, address data.",

  "datenschutz_decsription20_1": "For further privacy-related information, including the credit reference agencies used, please refer to PayPal's Privacy Policy:",

  "datenschutz_title8": "Security of Your Data / SSL Encryption",
  "datenschutz_description21": "In accordance with the legal regulations pursuant to § 13 (7) TMG, this site uses SSL encryption, recognizable by the lock symbol in the address bar of your browser. When SSL encryption is activated, data transmitted cannot be read by third parties.",

  "datenschutz_description22": "In most cases, this involves 256-bit encryption. If your browser does not support 256-bit encryption, we use 128-bit v3 technology instead. You can recognize whether an individual page of our website is transmitted in encrypted form by the closed display of the key or lock symbol in the lower status bar of your browser.",

  "datenschutz_description23": "In addition, we use appropriate technical and organizational security measures (TOM) to protect your data against accidental or intentional manipulation, partial or complete loss, destruction, or unauthorized access by third parties. Our security measures are continuously improved in line with technological developments.",

  "datenschutz_title9": "Data Subject Rights",

  "datenschutz_description24": "You have the right to request information about the personal data stored about you at any time and free of charge. Your rights also include confirmation, correction, restriction, blocking, and deletion of such data, as well as the provision of a copy of the data in a format suitable for transmission, as well as the revocation of consent given and objection. Legal retention obligations remain unaffected.",

  "datenschutz_description25": "Your rights arise in particular from the following provisions of the GDPR:",

  "datenschutz_list26": "<li>Article 7 (3) - Right to withdraw consent</li><li>Article 12 - Transparent information, communication, and modalities for the exercise of the rights of the data subject</li> <li>Article 13 - Information to be provided where personal data are collected from the data subject</li> <li>Article 14 - Information to be provided where personal data have not been obtained from the data subject</li> <li>Article 15 - Right of access by the data subject, right to confirmation, and communication of personal data</li> <li>Article 16 - Right to rectification</li> <li>Article 17 - Right to erasure ('right to be forgotten')</li><li>Article 18 - Right to restriction of processing</li><li>Article 19 - Notification obligation regarding rectification or erasure of personal data or restriction of processing</li><li>Article 20 - Right to data portability</li><li>Article 21 - Right to object</li><li>Article 22 - Right not to be subject to a decision based solely on automated processing, including profiling</li><li>Article 77 - Right to lodge a complaint with a supervisory authority</li>",

  // TERMS AND CONDITIONS

  "agb_title1": "1. GENERAL",
  "agb_description1": "Funfsterne operates an internet-based software application (hereinafter referred to as the 'Service') that supports entrepreneurs in their activities on trading platforms. The Service is available through the internet, including the domain Funfsterne.de. Funfsterne, located at Kurfürstendamm 45, 10707 Berlin, Germany, is the provider of the Service. These terms and conditions apply to all current and future business relationships between the user and Funfsterne. Any deviating, conflicting, or supplementary terms and conditions of the user (including terms of purchase) do not become part of the contract unless their applicability is expressly agreed upon at the time of contracting. Any standard reference by the user to their terms and conditions is expressly objected to.",

  "agb_title2": "2. CONTRACT CONCLUSION",
  "agb_description2": "The subject of the usage agreement is the provision of the Service to the user for utilizing its functionalities. The scope of services, including technical functionalities and the extent of the offered services, depends on the selected tariff during the registration process. The specific features of a tariff are described in the service description. Additionally, Funfsterne reserves the right to offer additional services beyond the selected tariff. The costs and scope of such additional services are also outlined in the service description and the valid price list. To use the Service, the user must have an internet connection, an internet-enabled device, and an up-to-date internet browser, which are the user's responsibility. The achievement of a specific sales success on trading platforms is not part of the contract. Funfsterne only provides the opportunity to use certain services, and the successful implementation of these services is solely the responsibility of the user.",

  "agb_title3": "3. CONTRACTUAL AGREEMENT",
  "agb_description3": "The use of the Service is only possible within the framework of a usage agreement. The conclusion of a contract for the use of the Service is only possible for entrepreneurs, legal entities under public law, or public special funds. The user is obliged to provide accurate information about themselves and their company at the time of contract conclusion and to keep their data up to date. Funfsterne offers the conclusion of paid usage agreements. By completing and submitting the corresponding form, the user declares their intention to enter into a contract with Funfsterne for the use of the services. The user has the option to review their personal and company information before submitting the form and correct any relevant fields if necessary. They can also review the selected services and make changes by selecting the corresponding checkboxes. By clicking the appropriate button, the user submits a binding offer to conclude a usage agreement. Funfsterne accepts the offer by sending an order confirmation, which includes the access data for the services. The contract can be concluded in German or, depending on the platform used, in English. While Funfsterne stores the contract text, it is not accessible to the customer.",

  "agb_title4": "4. PROVISION OF THE SERVICE",
  "agb_description4": "Funfsterne provides the Service to the user upon activation. The point of transfer for the provision of the Service is the router exit of Funfsterne's data center. Funfsterne ensures a service availability of 97% on an annual average at the transfer point.",

  "agb_title5": "5. USAGE AUTHORIZATION, RIGHTS",
  "agb_description5": "Funfsterne grants the user a non-exclusive, non-licensable, and non-transferable right to use the Service for their own business purposes during the contract term. Ungranted rights are not applicable to the user. The user is specifically prohibited from providing access to the Service to third parties or providing services for third parties using the Service. This exclusion does not apply to service providers acting directly on behalf of the customer. If a database or databases, or a database work or works, are created on Funfsterne's server during the contract term, all rights to these databases or database works belong to the user. The user remains the owner of the databases or database works even after the contract ends.",
  "agb_title6": "6. FEES, PAYMENT",
  "agb_description6": "If the use of the Service is chargeable, the costs are determined according to the information provided in the respective price list. All prices are subject to the applicable value-added tax. Payments must be made in accordance with the respective service description and can be made using the available payment methods. If the parties agree on payment by direct debit, the user grants Funfsterne a SEPA direct debit mandate for each due payment, including recurring payments and varying amounts. The advance notice period (pre-notification) is reduced to 1 day, if permissible. The user undertakes to ensure sufficient funds in the account. Costs incurred due to non-payment or chargeback of a payment order are the responsibility of the customer, unless the non-payment or chargeback was caused by Funfsterne. In addition, Funfsterne charges a processing fee of 10€ net. Funfsterne invoices are issued in electronic form and sent via email. Funfsterne may also provide the user with the option to download invoice documents in file format within their user account. If the user requests additional delivery by postal mail, Funfsterne is entitled to charge a flat fee according to the price list. Funfsterne acts as an intermediary platform between the entrepreneur and the tester. In this role, Funfsterne manages the credit balance deposited by the entrepreneur on the platform and uses it to make payments to testers in managed campaigns. Deposited funds on Funfsterne must be used for tool services (tester payouts, purchase of managed/fully managed surcharges). Withdrawal of Funfsterne credit in cash or transfer to a bank account is not possible.",

  "agb_title7": "7. USER'S OBLIGATIONS",
  "agb_description7": "The user selects access credentials for the purpose of using the Service. The user is obliged to keep these access credentials confidential and to inform Funfsterne immediately about any loss or unauthorized use of the access credentials by third parties. The user is not allowed to provide access credentials to third parties. Funfsterne is entitled to block access credentials if there is suspicion of unauthorized use or abuse of the data. The user may only use the Service for their own business purposes. The user undertakes not to use the services to promote offerings that compete with Funfsterne or to collect data or contact details of third parties within the services for the purpose of contacting or establishing contractual relationships with these third parties outside the services. If the user discovers that the Service is not working or not functioning properly, they are obligated to promptly notify Funfsterne in writing.",

  "agb_description88": ".",


  "agb_title8": "8. DATA PROTECTION",
  "agb_description8": "The parties will comply with the applicable data protection regulations, particularly those valid in Germany. Funfsterne is entitled to statistically analyze user data in anonymous form and use it for its own purposes, including advertising purposes. This is done primarily for the development of the services.",
  "agb_title9": "9. LIABILITY",
  "agb_description9": "Funfsterne is liable for the ratings submitted and assures to submit all the ratings booked. After sending the final report, Funfsterne guarantees the existence of the ratings for 90 days. After the end of the period, Funfsterne is no longer responsible and the order is final.",

  "agb_title10": "10. DURATION, TERMINATION",
  "agb_description10": "The term of the contract for the paid use of the Service begins upon activation by Funfsterne and runs on a monthly basis for an indefinite period. If the user chooses a different payment interval, the contract term is extended to the selected payment interval. The term of a contract for the paid use of the Service is automatically extended by the respective contract term if not terminated in due time. If used services, particularly additional services, exceed the contract term, the contract cannot be terminated before the expiration of the additional services' term. Termination, including termination by Funfsterne, must be in text form and requires a notice period of two weeks prior to the end of the respective term. The right to terminate without notice for good cause remains unaffected. Such termination also requires text form. If the user unilaterally discontinues the use of the Service before the termination notice period expires, they remain obligated to pay the fees. If the customer is in substantial payment arrears with the monthly costs or any other payment obligations, Funfsterne may revoke the user's right to use the services until the payment arrears are fully remedied and/or refuse further services. The right to terminate without notice for good cause remains in effect alongside the right to revoke the user's usage authorization.",

  "agb_title11": "11. CHANGES TO THESE TERMS AND CONDITIONS, TRANSFER OF RIGHTS",
  "agb_description11": "Funfsterne has the right to change the provisions regarding the services to be provided at its reasonable discretion, considering technical requirements and market conditions, as far as this is reasonable for the user. Changes to these General Terms and Conditions will be published within the Service. The user will be informed about changes to the terms and conditions, which do not fall under section 1, in writing. The changes become effective unless the user objects to the respective changes in writing within 14 days after receiving the notification of changes. The user will be informed about the significance of their silence along with the notification of changes. If the user objects to the changes, Funfsterne is entitled to terminate the contract at the end of the current term. If Funfsterne does not exercise this termination right, the contract will continue under the conditions in effect until then.",

  "agb_title12": "12. FINAL PROVISIONS",
  "agb_description12": "The laws of the Federal Republic of Germany apply. The provisions of the UN Convention on Contracts for the International Sale of Goods (CISG) do not apply. If the user is a merchant, a legal entity under public law, or a public special fund, the exclusive place of jurisdiction for all disputes arising from this contract is the registered office of Funfsterne. The same applies if the user does not have a general place of jurisdiction in Germany or if their residence or habitual abode is unknown at the time the legal action is filed.",



  "discount_modal_title": "Special Offer: Up to 30% New Customer Discount!",

  "discount_modal_paragraph_0": "Dear prospective customers,",

  "discount_modal_paragraph_1": "Our reputation has been built through years of hard work, dedication to quality, and continuous feedback from our esteemed customers. Trust isn't simply granted; it's earned. And it's for this very reason that we're now taking a bold step:",

  "discount_modal_paragraph_2": "We are passing on our entire profit through this discount, just to ensure that you get to know us and experience our outstanding services firsthand.",

  "discount_modal_paragraph_3": "Imagine the feeling of having found the best deal on the market, the elation of an excellent business transaction, and the confidence of having made the right decision. We are giving you the push to try us out, with the assurance that you won't be disappointed.",

  "discount_modal_paragraph_4": "Take advantage of this exclusive offer and save now",

  "discount_modal_list_item_1": "20% on the Starter Package",
  "discount_modal_list_item_2": "25% on the Business Package",
  "discount_modal_list_item_3": "30% on the Pro Package",

  "discount_modal_paragraph_5": "See for yourself why so many customers trust and love us. To avail this, please use the following when filling out the contact form",

  "discount_modal_paragraph_6": "Discount Code: #NK2023",



}