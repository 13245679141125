import Form from "./form"
import FAQ from "./faq"

export default function index() {
  return (
    <section className='max-w-screen-xl px-4 py-8 mx-auto sm:py-12 lg:py-16 lg:px-8'>
        <div className="grid items-center grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-20 place-content-center">
            <Form/>
            <FAQ/>
        </div>
    </section>
  )
}
