import Image from "next/image";
import Link from "next/link";
import { useTranslation } from '@/components/useTranslation'
import { AiOutlineMail } from "react-icons/ai";
import { FiPhone } from "react-icons/fi";
import { GrLocation } from "react-icons/gr";

export default function Footer() {

  const t = useTranslation();

  return (
    <footer aria-label="Site Footer" className="bg-[#fffdfd] border-t border-gray-100 mt-16">
      <div className="max-w-screen-xl px-4 pt-16 pb-16 mx-auto sm:px-6 lg:px-8 lg:pt-24">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
          <div className='order-last md:order-first'>
            <div className="flex justify-center text-teal-600 sm:justify-start">
              <Image src={"/images/global/logo.png"} width={200} height={100} alt="Funfsterne Logo" />
            </div>
            <p className="mt-6 text-center text-gray-500 sm:max-w-xs sm:text-left !text-sm">
              {t.copyright}
            </p>
          </div>
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:col-span-2">
            <div className="text-center sm:text-left">
              <p className="text-lg font-medium text-gray-900">{t.services}</p>
              <nav aria-label="Footer About Nav" className="mt-8">
                <ul className="space-y-4 text-sm">
                  <li>
                    <Link
                      className="text-gray-700 transition hover:text-gray-700/75"
                      href={`/platform/${t.url_google}`}
                    >
                      Google {t.buy_reviews}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-gray-700 transition hover:text-gray-700/75"
                      href={`/platform/${t.url_google_local_guide}`}
                    >
                      Google Local Guide {t.buy_reviews}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-gray-700 transition hover:text-gray-700/75"
                      href={`/platform/${t.url_jameda_clear}`}
                    >
                      Jameda {t.buy_clear}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-gray-700 transition hover:text-gray-700/75"
                      href={`/platform/${t.url_trustpilot}`}
                    >
                      Trustpilot {t.buy_reviews}
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="text-gray-700 transition hover:text-gray-700/75"
                      href={`/platform/${t.url_arbeitgeber}`}
                    >
                      {t.employer} {t.buy_reviews}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-gray-700 transition hover:text-gray-700/75"
                      href={`/platform/${t.url_google_clear}`}
                    >
                      Google {t.buy_clear}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-gray-700 transition hover:text-gray-700/75"
                      href={`/platform/${t.url_wunschplattform}`}
                    >
                      {t.wunschplattform} {t.buy_reviews}
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="text-center sm:text-left">
              <p className="text-lg font-medium text-gray-900">{t.legal}</p>
              <nav aria-label="Footer Services Nav" className="mt-8">
                <ul className="space-y-4 text-sm">
                  <li>
                    <Link
                      href='/agb'
                      className="text-gray-700 transition hover:text-gray-700/75"
                    >
                      {t.agb}
                    </Link>
                  </li>
                  <li>
                    <Link
                      href='/datenschutz'
                      className="text-gray-700 transition hover:text-gray-700/75"
                    >
                      {t.privacy_policy}
                    </Link>
                  </li>
                  <li>
                    <Link
                      href='/impressum'
                      className="text-gray-700 transition hover:text-gray-700/75"
                    >
                      {t.impressum}
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="text-center sm:text-left">
              <p className="text-lg font-medium text-gray-900">{t.contact}</p>
              <ul className="mt-8 space-y-4 text-sm">
                <li>
                  <a
                    className="flex items-center justify-center gap-1.5 sm:justify-start"
                    href="mailto:info@funfsterne.de">
                    <AiOutlineMail className="w-5 h-5 text-gray-900 shrink-0" />
                    <span className="text-gray-700">info@funfsterne.de</span>
                  </a>
                </li>
                <li>
                  <a
                    className="flex items-center justify-center gap-1.5 sm:justify-start"
                    href="tel:+49 30 200 09 515"
                  >
                    <FiPhone className="w-5 h-5 text-gray-900 shrink-0" />
                    <span className="text-gray-700">+49 30 200 09 515</span>
                  </a>
                </li>
                <li className="flex items-start justify-center gap-1.5 sm:justify-start">
                  <GrLocation className="w-5 h-5 text-gray-900 shrink-0" />
                  <address className="-mt-0.5 not-italic text-gray-700">{t.address} </address>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}


